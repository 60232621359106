// extracted by mini-css-extract-plugin
export var bg = "LandingPageHeroTimer-module--bg--LhJMo";
export var cBlack = "LandingPageHeroTimer-module--c-black--X61Qp";
export var cBlue = "LandingPageHeroTimer-module--c-blue--tLiME";
export var cGray = "LandingPageHeroTimer-module--c-gray--KGPfz";
export var cNavy = "LandingPageHeroTimer-module--c-navy--Q3X70";
export var cNavyBg = "LandingPageHeroTimer-module--c-navy-bg--5tBVB";
export var cWhite = "LandingPageHeroTimer-module--c-white--KuG64";
export var colon = "LandingPageHeroTimer-module--colon--WoN3N";
export var column = "LandingPageHeroTimer-module--column--h3Aic";
export var counter = "LandingPageHeroTimer-module--counter--E2lYa";
export var displayLarge = "LandingPageHeroTimer-module--display-large--2VZWQ";
export var displaySmall = "LandingPageHeroTimer-module--display-small--HKnoU";
export var element = "LandingPageHeroTimer-module--element--WbgAh";
export var filler = "LandingPageHeroTimer-module--filler--EE6Mk";
export var fwBold = "LandingPageHeroTimer-module--fw-bold--Uph7g";
export var h1 = "LandingPageHeroTimer-module--h1--myQGk";
export var h2 = "LandingPageHeroTimer-module--h2--qqhAT";
export var h3 = "LandingPageHeroTimer-module--h3--MAYIC";
export var h4 = "LandingPageHeroTimer-module--h4--A9KZd";
export var label = "LandingPageHeroTimer-module--label--CdrFF";
export var row = "LandingPageHeroTimer-module--row--zvujE";
export var sLarge = "LandingPageHeroTimer-module--s-large--QmliR";
export var sMedium = "LandingPageHeroTimer-module--s-medium--kPZs0";
export var sSmall = "LandingPageHeroTimer-module--s-small--31sp9";
export var timer = "LandingPageHeroTimer-module--timer--wYHGv";