import React, { FC } from "react";
import HtmlParser from "react-html-parser";
import cn from "utils/cn";

import * as styles from "./LandingPageHero.module.scss";
import ILandingPageHero from "./LandingPageHero.type";
import LandingPageHeroNewsletter from "./LandingPageHeroNewsletter";
import LandingPageHeroTimer from "./LandingPageHeroTimer";

const LandingPageHero: FC<ILandingPageHero> = ({ data }) => {
  return (
    <section className={styles.element}>
      <div className={cn(styles.container, "container")}>
        <div className={styles.content}>
          <h1 className="c-navy">{HtmlParser(data.title)}</h1>
          <div className={styles.line} />
          <p className={cn(styles.text, "s-large")}>{HtmlParser(data.text)}</p>
          <LandingPageHeroNewsletter />
        </div>
        <LandingPageHeroTimer className={styles.timer} launchDate={data.launchDate} />
      </div>
    </section>
  );
};

export default LandingPageHero;
