import React from "react";

import buildingIcon from "assets/vector/building-icon.svg";
import incomeIcon from "assets/vector/dolar-icon.svg";
import safetyIcon from "assets/vector/safety-icon.svg";
import moneyCicleIcon from "assets/vector/money-cicle-icon.svg";
import personIcon from "assets/vector/person-tick-icon.svg";
import DefaultSectionTemplate from "components/templates/DefaultSectionTemplate";

import * as styles from "./LandingPageAdvantages.module.scss";
import cn from "utils/cn";

const LandingPageAdvantages = () => {
  return (
    <DefaultSectionTemplate
      className={cn(styles.element, "padding-top padding-bottom")}
      title="Korzyści"
    >
      <div className={styles.container}>
        {items.map((item) => (
          <div className={styles.item} key={item.label}>
            <div className={styles.iconWrapper}>
              <img src={item.icon} alt="" />
            </div>
            <h3 className="h4">{item.label}</h3>
          </div>
        ))}
      </div>
    </DefaultSectionTemplate>
  );
};

export default LandingPageAdvantages;

const items = [
  { label: "Wysoki\ndochód", icon: incomeIcon },
  { label: "Szybkie\ntransakcje", icon: moneyCicleIcon },
  { label: "Sprawdzone\nprojekty", icon: personIcon },
  { label: "Platforma\nonline", icon: buildingIcon },
  { label: "Bezpieczeństwo", icon: safetyIcon },
];
