import React, { FC } from "react";

import DefaultSectionTemplate from "components/templates/DefaultSectionTemplate";
import Parse from "react-html-parser";
import cn from "utils/cn";

import * as styles from "./LandingPagePrinciple.module.scss";
import ILandingPagePrinciple from "./LandingPagePrinciple.type";

const LandingPagePrinciple: FC<ILandingPagePrinciple> = ({ data }) => {
  return (
    <DefaultSectionTemplate className={styles.element} title="Jak to działa?" id="jak-to-dziala">
      <div className={styles.container}>
        <div className={cn(styles.column, styles.left)}>
          <div className={styles.quote}>
            <h3 className={styles.heading}>{Parse(data.quote.title)}</h3>
            <p className="s-large">{Parse(data.quote.text)}</p>
          </div>
        </div>
        <div className={cn(styles.column, styles.right)}>
          <h3 className={styles.heading}>{Parse(data.rightColumn.title)}</h3>
          <p className="s-large">{Parse(data.rightColumn.text)}</p>
        </div>
      </div>
    </DefaultSectionTemplate>
  );
};

export default LandingPagePrinciple;
