import React from "react";

import DefaultSectionTemplate from "components/templates/DefaultSectionTemplate";
import { StaticImage } from "gatsby-plugin-image";
import HtmlParser from "react-html-parser";

import * as styles from "./LandingPageAbout.module.scss";
import ILandingPageAbout from "./LandingPageAbout.type";

const LandingPageAbout: React.FC<ILandingPageAbout> = ({ data }) => {
  return (
    <DefaultSectionTemplate title="O nas">
      <div className={styles.container}>
        <StaticImage
          className={styles.bg}
          src="../../../../assets/raster/glass-building-2.png"
          alt="Szklany budynek"
          quality={90}
        />
        <div className={styles.content}>
          <h3 className={styles.heading}>{HtmlParser(data.title)}</h3>
          <p className={styles.text}>{HtmlParser(data.text)}</p>
          {/* <Button>Dowiedz się więcej</Button> */}
        </div>
      </div>
    </DefaultSectionTemplate>
  );
};

export default LandingPageAbout;
