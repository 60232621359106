import { useLayoutEffect, useState } from "react";

import dayjs from "dayjs";

import { TAnyDate, TCountdown, TTwoDigitString } from "./LandingPageHeroTimer.type";

export const useCountdown = (date: TAnyDate) => {
  const [countdown, setCountdown] = useState<TCountdown>({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useLayoutEffect(() => {
    setCountdown(getCountdown(date));
    const interval = setInterval(() => {
      setCountdown(getCountdown(date));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return countdown;
};

/* returns days, hours, minutes and seconds from now to given date
 */
const getCountdown = (date: TAnyDate) => {
  let diff = getDiffInMiliseconds(date);

  const msPerSecond = 1000; // miliseconds
  const msPerMinute = msPerSecond * 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;

  const days = Math.floor(diff / msPerDay);

  diff -= msPerDay * days;

  const hours = Math.floor(diff / msPerHour);

  diff -= msPerHour * hours;

  const minutes = Math.floor(diff / msPerMinute);

  diff -= msPerMinute * minutes;

  const seconds = Math.floor(diff / msPerSecond);

  return {
    days: addZeroIfNeeded(days),
    hours: addZeroIfNeeded(hours),
    minutes: addZeroIfNeeded(minutes),
    seconds: addZeroIfNeeded(seconds),
  };
};

const getDiffInMiliseconds = (date: TAnyDate) => dayjs(date).diff(dayjs(), "millisecond");

const addZeroIfNeeded = (number: number): TTwoDigitString =>
  ((number < 10 ? "0" : "") + number.toString()) as TTwoDigitString;
