import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import cn from "utils/cn";

import { useCountdown } from "./LandingPageHeroTimer.hooks";
import * as styles from "./LandingPageHeroTimer.module.scss";
import ILandingPageHeroTimer from "./LandingPageHeroTimer.type";

const LandingPageHeroTimer: React.FC<ILandingPageHeroTimer> = ({ className, launchDate = "2022/06/30" }) => {
  const countdown = useCountdown(formatLaunchDate(launchDate));

  return (
    <div className={cn(styles.element, className)}>
      <StaticImage
        className={styles.bg}
        src="../../../../../assets/raster/glass-building.png"
        alt="Szklany budynek"
        quality={90}
      />
      <div className={cn(styles.timer, "s-large c-blue")}>
        <p className={styles.label}>Do startu platformy :</p>
        <div className={styles.row}>
          <div className={styles.column}>
            <span className={styles.counter}>{countdown.days}</span>
            <p className="s-medium">DNI</p>
          </div>
          <span className={styles.colon}>:</span>
          <div className={styles.column}>
            <span className={styles.counter}>{countdown.hours}</span>
            <p className="s-medium">GODZINY</p>
          </div>
          <span className={styles.colon}>:</span>
          <div className={styles.column}>
            <span className={styles.counter}>{countdown.minutes}</span>
            <p className="s-medium">MINUTY</p>
          </div>
          <span className={styles.colon}>:</span>
          <div className={styles.column}>
            <span className={styles.counter}>{countdown.seconds}</span>
            <p className="s-medium">SEKUNDY</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageHeroTimer;

const formatLaunchDate = (dateString: string) => {
  const [date, time] = dateString.split(" ");

  const [year, month, day] = date?.split(".");

  if (year && month && day && time) {
    return `${year}-${month}-${day}T${time}:000`;
  }

  return dateString;
};
