import React, { useRef } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import cn from "utils/cn";
import * as styles from "./LandingPageHeroNewsletter.module.scss";

const url = `https://unilaw.us14.list-manage.com/subscribe/post?u=81fb76daef70b1c82f3a713d4&id=7182c454fe`;

const LandingPageHeroNewsletter = () => {
  const inputRef = useRef<string>("");
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, message }) => {
        const isSuccess = message === "Thank you for subscribing!";
        return (
          <form
            className={styles.element}
            onSubmit={(e) => {
              e.preventDefault();
              subscribe({ MERGE0: inputRef.current } as any); // any because MERGE0 doesnt exist in types for react-mailchimp-subscribe
            }}
          >
            <h3 className="c-navy">Dołącz do listy oczekujących</h3>
            <div className={styles.inputGroup}>
              <div className={styles.inputWrapper}>
                <input
                  placeholder="Twój email"
                  type="email"
                  onChange={(e) => (inputRef.current = e.target.value)}
                  className="s-large c-black"
                />
              </div>
              <button className="h4 c-white" type="submit">
                Zapisz się
              </button>
            </div>
            {message && typeof message === "string" && (
              <p className={cn("s-medium", isSuccess ? styles.success : styles.error)}>
                {message.charAt(0) === "0"
                  ? "Nieprawidłowy format adresu email"
                  : message.includes("już zapisany")
                  ? "Ten email został już dodany do listy subskrypcji."
                  : message.includes("Too many subscribe")
                  ? "Zbyt dużo prób subskrypcji z tego emaila. Proszę, spróbuj ponownie za około 5 minut."
                  : isSuccess
                  ? "Dziękujemy za subskrypcję naszego newslettera"
                  : message.includes("(#")
                  ? message.split("(#")[0]
                  : message}
              </p>
            )}
          </form>
        );
      }}
    />
  );
};

export default LandingPageHeroNewsletter;
