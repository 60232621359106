import React, { createContext, FC } from "react";

import DefaultLayout from "components/templates/DefaultLayout";

import ILandingPage from "./LandingPage.type";
import LandingPageAbout from "./LandingPageAbout";
import LandingPageAdvantages from "./LandingPageAdvantages";
import LandingPageGuides from "./LandingPageGuides";
import LandingPageHero from "./LandingPageHero";
// import LandingPageInvestments from "./LandingPageInvestments";
import LandingPagePrinciple from "./LandingPagePrinciple";

import content from "./LandingPage.content";
import { graphql, StaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
export const LandingPageContext = createContext(content);

const LandingPage: FC<ILandingPage> = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          wpPage(slug: { eq: "strona-glowna" }) {
            homepage {
              aboutUs {
                text
                title
              }
              hero {
                text
                title
                launchDate
              }
              howItWorks {
                quote {
                  text
                  title
                }
                rightColumn {
                  text
                  title
                }
              }
            }
          }
        }
      `}
      render={({
        wpPage: {
          homepage: { aboutUs, hero, howItWorks },
        },
      }) => (
        <DefaultLayout>
          <Helmet
            title="Lendidea.com - bezpieczne inwestycje pod zastaw nieruchomości"
            meta={[
              {
                name: "description",
                content:
                  "Lendidea.com to prosty i użyteczny serwis bezpiecznych inwestycji online pod zastaw nieruchomości",
              },
            ]}
          />
          <LandingPageHero data={hero} />
          <LandingPagePrinciple data={howItWorks} />
          {/* <LandingPageInvestments /> */}
          <LandingPageAdvantages />
          <LandingPageAbout data={aboutUs} />
          {/* <LandingPageGuides /> */}
        </DefaultLayout>
      )}
    />
  );
};

export default LandingPage;
